.form {
  color: #d7d9c0;
    display:flex;
  justify-content: space-between;
  animation: slide-down 300ms ease-out forwards;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}