.task {
    color: #ecede2;
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding-bottom: 1rem;
  }
  
  .task h3 {
    margin: 0 0 0.25rem 0;
  }
  
  .description {
    font-style: italic;
  }
  
  .time {
    margin-top: 0.25rem;
    font-weight: bold;
    color: #ffffff;
    font-size: 1.25rem;
  }

  .task-edit {
    display: flex;
    justify-content: center;
    margin: 0rem 0rem;
    margin-top: -2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
  }

  .edit-card {
    margin-top: 1rem;
    margin-right: -2rem;
    justify-content:center;
  }