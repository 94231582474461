.button {
    cursor: pointer;
    font: inherit;
    border: none;
    background-color: #d7d9c0;
    color: white;
    padding: 0.75rem 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    font-weight: bold;
  }
  
  .button:hover,
  .button:active {
    background-color: #dfe2b8;
  }
  
  .icon {
    color: #28304b;
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
  }
  
  .badge {
    color: #28304b;
    background-color: #dfe2b8;
    padding: 0.25rem 1rem;
    border-radius: 15px;
    margin-left: 1rem;
    font-weight: bold;
  }
  
  .button:hover .badge,
  .button:active .badge {
    background-color: #e5e9b1;
  }
  
  .bump {
    animation: bump 300ms ease-out;
  }
  
  @keyframes bump {
    0% {
      transform: scale(1);
    }
    50% {
      background-color: #eaefa7;
      transform: scale(1.08);
    }
    100% {
      transform: scale(1);
    }
  }

  .text {
    color: #28304b;
  }