.tasks {
    max-width: 60rem;
    width: 90%;
    margin: 2rem auto;
    animation: appear 1s ease-out forwards;
  }
  
  .tasks ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  @keyframes appear {
    from {
      opacity: 0;
      transform: translateY(3rem);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .tasks p {
    color: #d7d9c0;
    text-align: center;
  }