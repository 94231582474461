.current {
    margin-top: 0rem;
    font-size: 3.5rem;
}
.current h2 {
    margin-top: 0rem;
    margin-bottom: 0rem;
    color:#102a7f;
}
.time {
    margin-top: 1rem;
    color:#102a7f;
    margin-bottom: -3rem;
}
.desc {
    font-size: 2rem;
}

.label {
    margin-top: 5rem;
    font-size: 1.5rem;
}