@import url('https://fonts.googleapis.com/css?family=Sono');

* {
  box-sizing: border-box;
}


html {
  
  font-family: "Sono", sans-serif;
}

body {
  margin: 0;
  background-color: #ecede2;
}

